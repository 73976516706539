export const clientNameFooter = "ROJO YOGA";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = ''; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "ESPAÇO ROJO DE YOGA LTDA";
export const nomeFornecedor = "ESPAÇO ROJO";
export const artigo = "o";
export const artigoCaps = "O";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/marcusrojo/?hl=pt",
    profile: "@marcusrojo"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com/@espacorojodeyoga4375",
  },
];

export const drawerClosedLinks = [
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = true;
export const drawerOpenedLinks = [{
  title: "Planos de Assinatura",
  link: "https://rojoyoga.com.br/",
}];
